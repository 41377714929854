<rs-full-height-dialog>
  <ng-container rs-dialog-header>
    <a
      mat-dialog-close
      rsTriggerClickOnEnter
      tabindex="0"
      (click)="closePopup()"
    >
      <mat-icon
        [ngClass]="{
          'd-none': !isPaymentSelectionCompleted
        }"
        [svgIcon]="backIcon"
      ></mat-icon>
    </a>

    <p class="m-0">{{ 'PAYING_WITH.Edit Payment' | translate | titlecase }}</p>
  </ng-container>

  <rs-payment-selection
    (isPaymentSelectionCompleted)="setIsPaymentSelectionCompleted($event)"
  ></rs-payment-selection>

  <button rs-style="text" class="mt-2 mb-3" (click)="openAddPaymentDialog()">
    <mat-icon [svgIcon]="addIcon"></mat-icon>

    <span>{{ 'PAYING_WITH.Add Payment Method' | translate | uppercase }}</span>
  </button>

  <rs-tips-selector *ngIf="isShowTips"></rs-tips-selector>
</rs-full-height-dialog>
