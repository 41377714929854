<form
  [formGroup]="form"
  [class.ng-invalid]="isInvalid"
  [class.rs-maximum-height]="label"
  class="rs-text-area__form"
>
  <label *ngIf="label" [for]="placeholder" class="rs-text-area__label">
    {{ label }}
  </label>
  <button
    class="rs-text-area"
    [class.rs-text-area-rtl]="canBeRightToLeft"
    tabindex="-1"
    [isActive]="isActive"
    (rsClickOutside)="onOutsideClick()"
    [ngClass]="[
      disabled ? 'rs-text-area-disabled' : '',
      isInvalid && !disabled ? 'rs-text-area-invalid' : '',
      isValid && !disabled ? 'rs-text-area-valid' : ''
    ]"
    rs-style="text-area"
  >
    <textarea
      #defaultInput
      class="rs-text-area__text-field m-0"
      [class.rs-text-area__text-field-padding]="applyPadding"
      matInput
      [required]="required"
      [formControl]="formControl"
      [placeholder]="placeholder"
      [id]="placeholder"
      (input)="reactOnInput()"
      (focus)="reactOnFocus()"
      (keydown.enter)="reactOnKeyDownEnter($event)"
      (keydown.tab)="reactOnKeyDownTab()"
      cdkTextareaAutosize
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="2"
    ></textarea>
  </button>

  <span *ngIf="hint && !isInvalid && !isValid" class="rs-text-area__hint">
    {{ hint }}
  </span>

  <ng-container *ngIf="isInvalid">
    <mat-error *ngIf="formControl?.errors?.['required'] && isInvalid">
      {{ 'VALIDATION.REQUIRED' | translate }}
    </mat-error>
  </ng-container>
</form>
