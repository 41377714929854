<form class="rs-phone-input__form" [class.rs-maximum-height]="label">
  <label *ngIf="label" class="rs-phone-input__label" [for]="label">
    {{ label }}
  </label>
  <div
    class="rs-phone-input"
    [ngClass]="[
      disabled ? 'rs-phone-input-disabled' : '',
      isInvalid && !disabled ? 'rs-phone-input-invalid' : '',
      isValid && !disabled ? 'rs-phone-input-valid' : ''
    ]"
  >
    <mat-icon class="rs-phone-input__icon" [svgIcon]="angleDown"></mat-icon>

    <input matInput type="text" class="d-none" [id]="label" />
    <ngx-mat-intl-tel-input
      #phoneInput
      format="national"
      class="w-100"
      [id]="label"
      [cssClass]="'rs-phone-control__input'"
      [ngClass]="[isValid || isInvalid ? 'pe-4' : '']"
      [enableSearch]="true"
      [enablePlaceholder]="true"
      [formControl]="phoneForm.controls.phoneNumber"
      [preferredCountries]="preferredCountries"
      [disabled]="disabled"
      [required]="requiredError"
      (input)="reactOnInput()"
      (click)="reactOnClick()"
      (countryChanged)="reactOnCountryChanged($event)"
    >
    </ngx-mat-intl-tel-input>

    <span class="rs-phone-input__suffix" *ngIf="isValid && !disabled">
      <mat-icon class="rs-input__icon" [svgIcon]="success"></mat-icon>
    </span>
  </div>
  <span
    *ngIf="hint && !(isInvalid || requiredError)"
    class="rs-phone-input__hint"
  >
    {{ hint }}
  </span>

  <span
    *ngIf="hasSuccessStatus && successMessage && isValid && !disabled"
    class="rs-phone-input__success"
  >
    {{ successMessage }}
  </span>

  <mat-error
    *ngIf="isInvalid && (phoneForm.controls['phoneNumber'].errors?.['validatePhoneNumber'] || isPhoneNumberInvalid || phoneGroup.controls['phoneNumber'].errors?.['validatePhoneNumber']) && !isValid"
  >
    {{ 'VALIDATION.Phone_format_is_invalid' | translate }}
  </mat-error>
  <mat-error *ngIf="requiredError">
    {{ 'VALIDATION.Phone_Number_is_required' | translate }}
  </mat-error>
</form>
