<div class="rs-password-strength">
  <div class="rs-password-strength__row rs-progress">
    <span [ngClass]="classes">
      {{ strengthLabel | translate | uppercase }}
    </span>
    <mat-progress-bar
      mode="determinate"
      [ngClass]="classes"
      [value]="strengthValue"
    >
    </mat-progress-bar>
  </div>
  <ol class="rs-password-strength__row rs-checks ps-0">
    <li *ngFor="let passwordCheck of passwordChecks" class="rs-check">
      <mat-icon [class.rs-checked]="passwordCheck.isChecked">
        {{ passwordCheck.isChecked ? 'check_circle' : 'check_circle_outline' }}
      </mat-icon>

      <span>
        {{ passwordCheck.label | translate }}
        <span class="visually-hidden">
          {{
            ', ' +
              ('CREATE_PASSWORD.password_checks.' +
                (passwordCheck.isChecked ? 'fulfilled' : 'not_fulfilled')
                | translate)
          }}
        </span>
      </span>
    </li>
  </ol>
</div>
