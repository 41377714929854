import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

import {
  Menu,
  MenuAvailability,
  Time,
  TimePeriod,
} from '../_interfaces/menu.model';
import { Format } from '../_enums/date-time.enum';
import { DayOfWeek } from '../_enums/day-of-week.enum';
import { DeliveryDataDateTime } from '../_interfaces/order.model';
import { DeliveryType } from '../_enums/order.enum';

import { ItemData, StateItem } from '../_interfaces/item.model';
import {
  isAvailableMenuForCurrentOrderType,
  isAvailableMenuForSelectedTime,
  isAvailableMenuItemForSelectedTime,
} from '../_utils/menus';

@Pipe({ name: 'rsMenuAvailabilityPipe' })
export class MenuAvailabilityPipe implements PipeTransform {
  public transform(menu: Menu, locale: string = 'en'): string {
    const currentWeekDay = moment()
      .format(Format.WeekDay)
      .toUpperCase() as DayOfWeek;

    let availabilityState: string = 'MENUS.ALL_DAY';
    let availabilityIndex: number = -1;
    let weekDayIndex: number = -1;

    if (menu.availability.length) {
      menu.availability.forEach(
        ({ daysOfWeek }: MenuAvailability, i: number) => {
          if (daysOfWeek) {
            const idx: number = daysOfWeek?.indexOf(currentWeekDay);

            if (idx !== -1) {
              availabilityIndex = i;
              weekDayIndex = idx;
            }
          }
        }
      );

      if (weekDayIndex === -1) {
        availabilityState = 'MENUS.CLOSED';
      } else {
        const period =
          menu.availability[availabilityIndex].periods[weekDayIndex] ||
          menu.availability[availabilityIndex].periods[0];

        availabilityState = this.getTimePeriod(period, locale);
      }
    }

    return availabilityState;
  }

  private getTimePeriod = (period: TimePeriod, locale: string = 'en'): string =>
    this.formulateTime(period.from, locale) +
    ' - ' +
    this.formulateTime(period.to, locale);

  private formulateTime = (time: Time, locale: string = 'en'): string =>
    moment()
      .locale(locale)
      .set('hours', time.hours)
      .set('minutes', time.minutes)
      .format('h:mmA');
}

@Pipe({ name: 'rsIsOrderWarning', standalone: true })
export class RsIsOrderWarning implements PipeTransform {
  transform(
    menuId: string,
    orderType: DeliveryType,
    selectedOrderPeriod: DeliveryDataDateTime,
    menus: Menu[],
    item?: StateItem | ItemData
  ): boolean {
    const isAvailableForCurrentOrderType: boolean =
      isAvailableMenuForCurrentOrderType(menuId, orderType, menus);

    const isAvailableForSelectedTime: boolean = isAvailableMenuForSelectedTime(
      menuId,
      orderType,
      selectedOrderPeriod,
      menus,
      item
    );

    return (
      (!isAvailableForCurrentOrderType && !!orderType && !!menus.length) ||
      (!isAvailableForSelectedTime && !!selectedOrderPeriod)
    );
  }
}

@Pipe({ name: 'rsIsAvailableMenuForCurrentOrderType' })
export class IsAvailableMenuForCurrentOrderTypePipe implements PipeTransform {
  transform(menuId: string, orderType: DeliveryType, menus: Menu[]): boolean {
    return isAvailableMenuForCurrentOrderType(menuId, orderType, menus);
  }
}

@Pipe({ name: 'rsIsAvailableMenuForSelectedTime' })
export class IsAvailableMenuForSelectedTimePipe implements PipeTransform {
  transform(
    menuId: string,
    orderType: DeliveryType,
    selectedOrderPeriod: DeliveryDataDateTime,
    menus: Menu[],
    item?: StateItem | ItemData
  ): boolean {
    return isAvailableMenuForSelectedTime(
      menuId,
      orderType,
      selectedOrderPeriod,
      menus,
      item
    );
  }
}

@Pipe({ name: 'rsIsAvailableMenuItemForSelectedTime' })
export class IsAvailableMenuItemForSelectedTimePipe implements PipeTransform {
  transform(
    menuId: string,
    orderType: DeliveryType,
    selectedOrderPeriod: DeliveryDataDateTime,
    menus: Menu[],
    isAvailable: boolean
  ): boolean {
    return isAvailableMenuItemForSelectedTime(
      menuId,
      orderType,
      selectedOrderPeriod,
      menus,
      isAvailable
    );
  }
}
