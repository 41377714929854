import { find } from 'lodash';

import { Item } from '../_interfaces/item.model';
import { Menu } from '../_interfaces/menu.model';
import { QRSettingsType } from '../_interfaces/qrSettings.model';
import { Amount } from '../_models/currency.model';

import { environment } from 'src/environments/environment';
import {
  AUTHENTICATION_PAGES_PATHS,
  DEFAULT_SEPARATOR,
  PROFILE_PAGES,
  WELCOME_PAGE,
} from '../_constants/common';
import { DEFAULT_CURRENCY } from '../_constants/currency.constant';

export function copy<T>(obj: any): T {
  return JSON.parse(JSON.stringify(obj));
}

export const splitStringBySeparator = (
  stringValue: string | null | undefined,
  splitter: string = DEFAULT_SEPARATOR
): string[] => (stringValue ? stringValue.split(splitter) : []);

export const joinStringsWithSpaceBetween = (
  strings: (string | null | undefined)[]
) => strings.filter(Boolean).join(' ');

export const getFullName = (firstName: string, lastName: string): string =>
  joinStringsWithSpaceBetween([firstName, lastName]);

export function getPageFromConfig(config: any, name: string): any {
  return find(config?.pages, ['name', name]);
}

export function getPageData(config: any, name: string): any {
  return {
    pageHeadline: getPageFromConfig(config, name)?.pageHeadline,
    pageSubHeadline: getPageFromConfig(config, name)?.pageSubHeadline,
    bannerImage: getPageFromConfig(config, name)?.bannerImage,
    seo: getPageFromConfig(config, name)?.seo,
    ...getPageFromConfig(config, name)?.pageData,
  };
}

export const getItemIdsInMenus = (Menus: Menu[]): string[] => {
  let itemIds: string[] = [];

  Menus.forEach((Menu: Menu) => {
    for (let i = 0; i < Menu.sections.length; i++) {
      find(Menu.sections[i].items, (item: Item) => {
        itemIds.push(item.itemId);
      });
    }
  });

  return itemIds;
};
export const getPathWithoutBaseUrl = (locationUrl: string): string => {
  const baseUrl: string = environment.baseUrl + '/';
  return locationUrl.split(baseUrl).join('');
};

export const getVenueName = (locationUrl: string): string => {
  return getPathWithoutBaseUrl(locationUrl).split('/')[0];
};

export const getCurrentSettingsTypeFromQueryParams = (
  url: string
): QRSettingsType | undefined => {
  switch (true) {
    case url.includes('=order') || url.includes('/order/'):
      return 'togo';
    case url.includes('=menu') || url.includes('/menu/'):
      return 'menu';
    case url.includes('dine-in'):
      return 'dine-in';
    default:
      return undefined;
  }
};

export const getParsedVenueName = (locationUrl: string): string => {
  const venueNameChunks: string[] = getVenueName(locationUrl).split('-');
  let venueName: string = '';
  venueNameChunks
    .filter(chunk => !!chunk)
    .forEach(chunk => {
      venueName += chunk.replace(chunk[0], chunk[0].toUpperCase());
      venueName += ' ';
    });

  return venueName;
};
const isPageOneOfDefined = (
  locationUrl: string,
  definedPages: string[]
): boolean => {
  if (!definedPages?.length) {
    return false;
  }
  const pageIndex: number = definedPages.findIndex(
    (path: string) => locationUrl.indexOf(path) > 0
  );

  return pageIndex > -1;
};
export const isAuthenticationPage = (locationUrl: string): boolean => {
  return isPageOneOfDefined(locationUrl, AUTHENTICATION_PAGES_PATHS);
};
export const isWelcomePage = (locationUrl: string): boolean => {
  return isPageOneOfDefined(locationUrl, [WELCOME_PAGE]);
};

export const isProfilePage = (locationUrl: string): boolean => {
  return isPageOneOfDefined(locationUrl, PROFILE_PAGES);
};

export function sortByDate(a: any, b: any): number {
  return +new Date(b.date) - +new Date(a.date);
}

export function sortByTime(a: any, b: any): number {
  return +new Date(a.time) - +new Date(b.time);
}

export const removeCommas = (str: string) => str.replace(/,/g, '');

export const isDineIn = (field: string | string[]): boolean => {
  return typeof field === 'string'
    ? field.includes('/dine-in/')
    : field.includes('dine-in');
};

export function getHomePageUrlForRedirection(
  venueName: string,
  url: string
): string {
  return isDineIn(url) ? `/${venueName}/dine-in/menus` : `/${venueName}/home`;
}

export const camelCaseFormat = (title: string): string =>
  title
    .split(/[\s_]+/)
    .map((word, index) =>
      index === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('');

export const isStringTrue = (string: string): boolean => string === 'true';

export const getRandomNumber = (): number => {
  if (!window?.crypto) {
    return parseInt(String(Math.random() * 1000));
  }

  if (typeof window !== 'undefined' && window?.crypto) {
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);

    return array[0];
  } else {
    return parseInt(String(Math.random() * 1000));
  }
};

export const isObjectEmpty = (object: object): boolean =>
  !object || !Object.keys(object)?.length;

export const doesObjectHasKey = (object: any, key: string): boolean =>
  !!object && typeof object === 'object' && object.hasOwnProperty(key);

export const getObjectValueByKey = (object: any, key: string): any =>
  doesObjectHasKey(object, key) ? object[key] : undefined;

export const getAmountObject = (amount: number): Amount => ({
  amount,
  currency: { ...DEFAULT_CURRENCY },
});

export const getVenueNameLinkStyle = (venueName: string): string =>
  venueName
    ?.replace(/[\s]+/g, '-')
    .replace(/[^a-zA-Z0-9\-]+/g, '')
    .toLowerCase() || '';

export const getLinkBasedOnFormattedVenueName = (
  formattedVenueName: string,
  linkParts: string[]
): string => {
  linkParts.forEach(
    (value, index) => (linkParts[index] = decodeURIComponent(value))
  );

  const linkTail = linkParts.join('/');

  return `/${formattedVenueName}/${linkTail}`;
};
