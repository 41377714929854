<section
  class="rs-pay-with d-flex flex-column"
  [dir]="'languageDirection' | translate"
>
  <div class="pb-0">
    <rs-payment-methods-options
      [availablePaymentMethods]="availablePaymentMethods"
      [selectedPaymentMethod]="selectedPaymentMethod"
      [selectedCard]="selectedCard"
      [paymentMethodsType]="PaymentMethodsType.PaymentDetails"
      (onPaymentMethodChange)="onPaymentToggleChange($event)"
      (handleNewPaymentCard)="handleNewPaymentCard($event)"
      (managePaymentCard)="addPaymentCard()"
    >
    </rs-payment-methods-options>
  </div>

  <ng-container *ngIf="selectedPaymentMethod == paymentMethods.gift_card">
    <ng-container
      *ngIf="selectedGiftCard$ | async as selectedGiftCard; else newGiftCard"
    >
      <div
        class="d-flex flex-row w-100 rs-pay-with__gift-cards rs-pay-with__padded-section"
      >
        <img src="assets/img/gift-card.png" height="16" width="24" alt="" />
        <div class="d-flex flex-column rs-pay-with__gift-cards-wrapper">
          <div class="d-flex flex-row justify-content-between">
            <span class="rs-pay-with__gift-cards-number">
              {{ selectedGiftCard?.giftCardNumber | rsGiftCarNumberMask }}
            </span>
            <span class="fw-bold">
              {{
                selectedGiftCard.currentBalance
                  | cents2DollarsView
                  | rsEasternArabicNumerals
              }}
            </span>
          </div>

          <div
            class="d-flex flex-row justify-content-between rs-pay-with__gift-cards-details"
          >
            <span class="fw-bold">
              {{
                selectedGiftCard.currentBalance
                  | cents2DollarsView
                  | rsEasternArabicNumerals
              }}
            </span>
            <a
              class="rs-pay-with__gift-cards-remove"
              (click)="clearGiftCardSelection()"
            >
              {{ 'PAY_NOW.REMOVE' | translate | uppercase }}
            </a>
          </div>

          <span *ngIf="selectedGiftCard.expirationDate">
            {{ selectedGiftCard.expirationDate }}
          </span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="selectedGiftCard$ | async; let selectedGiftCard">
    <ng-container *ngIf="isShowAdditionalPayment">
      <div
        class="rs-pay-with__selected-gift-card d-flex justify-content-between pt-2"
      >
        <div class="d-flex flex-column">
          <h3 class="fw-bold m-0 rs-pay-with__balance">
            {{ 'GIFT_CARDS.balance' | translate }}
          </h3>
          <span class="rs-pay-with__left-to-pay">
            {{ 'PAY_NOW.Amount_left' | translate }}
          </span>
        </div>

        <p class="m-0 rs-pay-with__balance">
          {{
            selectedGiftCard.currentBalance!
              | calculateRemainingTotalAfterGiftCard
              | cents2DollarsView
              | rsEasternArabicNumerals
          }}
        </p>
      </div>

      <div class="rs-pay-with__padded-section pt-3">
        <span class="rs-pay-with__title mb-0">
          {{ 'CART.complete_payment' | translate }}
        </span>
      </div>

      <div class="rs-pay-with__payment-method pb-0">
        <rs-payment-methods-options
          [availablePaymentMethods]="additionalAvailablePaymentMethods"
          [selectedPaymentMethod]="completePaymentMethod"
          [selectedCard]="selectedCard"
          [paymentMethodsType]="PaymentMethodsType.CompletePayment"
          (onPaymentMethodChange)="onCompletePaymentToggleChange($event)"
          (handleNewPaymentCard)="handleNewPaymentCard($event)"
          (managePaymentCard)="addPaymentCard()"
        >
        </rs-payment-methods-options>
      </div>
    </ng-container>
  </ng-container>

  <!-- remove class "d-none" when we want to show terms & conditions checkbox -->
  <div class="rs-checkbox-wrapper rs-pay-with__padded-section d-none">
    <mat-checkbox class="rs-pay-with__terms-checkbox">
      <span class="rs-pay-with__terms-wrapper">
        {{ 'PAY_NOW.agree_to' | translate }}
        <a href="" class="rs-pay-with__terms">
          {{ 'PAY_NOW.terms_conditions' | translate }}
        </a>
      </span>
    </mat-checkbox>
  </div>
</section>

<ng-template #newGiftCard>
  <div class="rs-pay-with__padded-section">
    <span class="rs-pay-with__scan fw-bold mb-3 ms-1">
      {{ 'CARD.enter__info' | translate }}
    </span>

    <div class="mt-1 d-flex flex-row rs-pay-with__scan-wrapper">
      <form [formGroup]="giftCardForm" class="ms-1 w-100">
        <rs-input
          [placeholder]="'CARD.card_number' | translate"
          [control]="giftCardCodeControl"
          [injectedWarning]="
            (selectedGiftCardError$ | async)
              ? ('PAY_NOW.Invalid_Gift_Card' | translate)
              : ''
          "
          (onKeyDownEnter)="
            setGiftCardForCheckout(
              giftCardForm.controls['giftCardCode'].value || ''
            )
          "
        ></rs-input>
      </form>

      <button
        *ngIf="giftCardForm.get('giftCardCode'); let giftCardCodeControl"
        [id]="id.checkout.giftCardActionButton"
        rs-style="bold"
        (click)="
          !!giftCardCodeControl?.value
            ? setGiftCardForCheckout(giftCardCodeControl?.value!)
            : scanGiftCardQRCode()
        "
      >
        <mat-icon *ngIf="!giftCardCodeControl?.value">
          qr_code_scanner
        </mat-icon>
        <span class="rs-pay-with__scan-text">
          {{
            (giftCardCodeControl?.value ? 'PAY_NOW.Apply' : 'PAY_NOW.Scan')
              | translate
              | uppercase
          }}
        </span>
      </button>
    </div>
  </div>
</ng-template>
