<form *ngIf="formGroup" class="w-100" [formGroup]="formGroup">
  <rs-input
    rs-input-style="password"
    [required]="true"
    [placeholder]="'CREATE_PASSWORD.password' | translate"
    [control]="passwordControl"
  >
  </rs-input>

  <rs-password-strength
    [password]="formGroup.get('password')?.value"
  ></rs-password-strength>

  <rs-input
    rs-input-style="password"
    [required]="true"
    [placeholder]="'CREATE_PASSWORD.confirm_password' | translate"
    [control]="confirmPasswordControl"
  >
  </rs-input>
</form>
