import { A11yModule } from '@angular/cdk/a11y';
import { Component, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

const getTabbableElements = (): HTMLElement[] => {
  return Array.from(
    document.querySelectorAll('[role="menuitem"]')
  ) as HTMLElement[];
};

const activateElement = (event: KeyboardEvent): void => {
  const tabbableElements: HTMLElement[] = getTabbableElements();

  if (tabbableElements.length === 0) {
    return;
  }

  const currentIndex: number = tabbableElements.findIndex(
    (element: HTMLElement) => element === document.activeElement
  );
  let index: number | null = null;

  if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
    index = (currentIndex + 1) % tabbableElements.length;
  } else if (
    event.key === 'ArrowUp' ||
    (event.key === 'Tab' && event.shiftKey)
  ) {
    index =
      (currentIndex - 1 + tabbableElements.length) % tabbableElements.length;
  } else {
    return;
  }

  index !== null && index !== -1 && tabbableElements[index]?.focus();
};

@Component({
  selector: 'rs-menu-title',
  standalone: true,
  imports: [SharedModule],
  template: `<div
    class="w-100 rs-menu-title"
    (click)="ignoreClickIfTitle($event)"
  >
    <ng-content></ng-content>
  </div>`,
})
export class RsMenuTitleComponent {
  public ignoreClickIfTitle(event: Event): void {
    event.stopPropagation();
  }
}

@Component({
  selector: 'rs-menu-item',
  standalone: true,
  imports: [SharedModule],
  template: `<div
    class="rs-menu-item"
    [ngClass]="{ 'rs-menu-item--selected': selected }"
    mat-menu-item
    #rsMenuItem
    (keydown)="keyDownHandler($event)"
    rsTriggerClickOnEnter
  >
    <ng-content></ng-content>
  </div>`,
})
export class RsMenuItemComponent {
  @Input() selected!: boolean;

  public keyDownHandler(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Tab': {
        if (true) {
          event.preventDefault();
          event.stopPropagation();
          activateElement(event);
        }

        break;
      }
      default:
        break;
    }
  }
}

@Component({
  selector: 'rs-menu',
  standalone: true,
  imports: [SharedModule, A11yModule],
  template: `
    <div
      (keydown)="handleKeydown($event)"
      [matMenuTriggerFor]="menu"
      tabindex="0"
      rsTriggerClickOnEnter
      #languageMenuTrigger="matMenuTrigger"
      class="d-flex justify-content-center align-items-center"
    >
      <ng-content select="[rs-trigger]"></ng-content>
    </div>
    <mat-menu
      class="rs-menu"
      #menu="matMenu"
      [backdropClass]="isOverlap ? backdropClassForOverlaping : ''"
      (keydown)="handleKeydown($event)"
    >
      <div
        class="d-flex flex-column"
        [ngClass]="{ 'rs-bordered': isBordered, 'rs-default': !isBordered }"
      >
        <ng-content></ng-content>
      </div>
    </mat-menu>
  `,
})
export class RsMenuComponent {
  @Input('rs-bordered') set bordered(_: any) {
    this.isBordered = true;
  }

  @Input('rs-overlap-class') set overlapClass(className: string) {
    if (className) {
      this.isOverlap = true;
      this.backdropClassForOverlaping = className;
    }
  }

  @Input('rs-overlap') set overlap(_: string) {
    this.isOverlap = true;
  }

  @ViewChild('languageMenuTrigger') languageMenuTrigger!: MatMenuTrigger;

  public isBordered!: boolean;
  public isOverlap!: boolean;
  public backdropClassForOverlaping: string =
    'rs-menu__backdrop-for-overlapping';
  public handleKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Escape': {
        this.languageMenuTrigger.closeMenu();
        break;
      }

      case 'ArrowDown':
      case 'ArrowUp':
      case 'Tab': {
        if (this.languageMenuTrigger.menuOpen) {
          event.preventDefault();
          event.stopPropagation();
          activateElement(event);
        }

        break;
      }
      default:
        break;
    }
  }
}
