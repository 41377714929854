<div class="d-flex flex-column rs-paying-with">
  <div class="d-flex flex-row justify-content-between">
    <div
      class="d-flex align-items-end rs-paying-with__text rs-paying-with__hint pb-2"
    >
      {{ 'PAYING_WITH.Paying with' | translate }}
    </div>
  </div>

  <a
    rsTriggerClickOnEnter
    tabindex="0"
    class="d-flex flex-row justify-content-between rs-paying-with__summary rs-card-container rs-card-container--outline"
    (click)="openEditPaymentDialog()"
  >
    <div class="rs-paying-with__text rs-paying-with__summary-text">
      <ng-container
        *ngTemplateOutlet="
          rsSelectedPayment;
          context: {
            selectedPayment: selectedPaymentMethod,
            cardNumber:
              selectedPaymentMethod === paymentMethods.gift_card
                ? (selectedGiftCard$ | async)?.giftCardNumber
                : selectedPaymentMethod === paymentMethods.card
                ? (selectedPaymentCard$ | async)?.redactedCardNumber
                : '',
            cardBrand:
              selectedPaymentMethod === paymentMethods.card
                ? (selectedPaymentCard$ | async)?.cardBrand
                : ''
          }
        "
      ></ng-container>

      <ng-template
        [ngIf]="
          selectedPaymentMethod === paymentMethods.gift_card &&
          !(isGiftCardCoverTotal$ | async)
        "
      >
        <ng-container
          *ngTemplateOutlet="
            rsSelectedPayment;
            context: {
              selectedPayment: completePaymentMethod,
              cardNumber:
                completePaymentMethod === paymentMethods.gift_card
                  ? (selectedGiftCard$ | async)?.giftCardNumber
                  : completePaymentMethod === paymentMethods.card
                  ? (selectedPaymentCard$ | async)?.redactedCardNumber
                  : '',
              cardBrand:
                completePaymentMethod === paymentMethods.card
                  ? (selectedPaymentCard$ | async)?.cardBrand
                  : ''
            }
          "
        ></ng-container>
      </ng-template>
    </div>

    <div
      *ngIf="!hideTips"
      class="rs-paying-with__text rs-paying-with__summary-text d-flex align-items-center rs-paying-with__summary-item"
    >
      <span
        >{{ 'PAY_NOW.TIPS' | translate }} {{ ' ' + tips.percentage }}%
      </span>
    </div>
  </a>
</div>

<ng-template
  #rsSelectedPayment
  let-selectedPayment="selectedPayment"
  let-cardNumber="cardNumber"
  let-cardBrand="cardBrand"
>
  <div
    *ngIf="selectedPayment === paymentMethods.gift_card"
    class="rs-paying-with__card-info rs-paying-with__text rs-paying-with__summary-item d-flex align-items-center"
  >
    <mat-icon [svgIcon]="'Gift_Card'"></mat-icon>
    <span class="rs-paying-with__card-number">
      {{
        'PAYING_WITH.ending in XXXX'
          | translate: { data: cardNumber | rsCardLastFourDigits }
      }}
    </span>
  </div>
  <div
    *ngIf="selectedPayment === paymentMethods.card"
    class="rs-paying-with__card-info rs-paying-with__text rs-paying-with__summary-item d-flex align-items-center"
  >
    <mat-icon
      [svgIcon]="
        cardBrand ? (cardBrand | lowercase | titlecase).trim() : 'Default'
      "
    ></mat-icon>
    <span class="rs-paying-with__card-number">
      {{
        'PAYING_WITH.ending in XXXX'
          | translate: { data: cardNumber | rsCardLastFourDigits }
      }}
    </span>
  </div>
  <div
    *ngIf="selectedPayment === paymentMethods.google_pay"
    class="rs-paying-with__card-info rs-paying-with__text rs-paying-with__summary-item d-flex align-items-center"
  >
    <mat-icon [svgIcon]="'Google_Pay'"></mat-icon>
    <span class="rs-paying-with__card-number">
      {{ 'PAY_NOW.google-pay' | translate }}
    </span>
  </div>
  <div
    *ngIf="selectedPayment === paymentMethods.apple_pay"
    class="rs-paying-with__card-info rs-paying-with__text rs-paying-with__summary-item d-flex align-items-center"
  >
    <mat-icon [svgIcon]="'Apple_Pay'"></mat-icon>
    <span class="rs-paying-with__card-number">
      {{ 'PAY_NOW.apple-pay' | translate }}
    </span>
  </div>
</ng-template>
