import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { Store } from '@ngxs/store';

import { VenueState } from '../_shared/_ngxs/venue.state';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly store: Store,
    @Inject(PLATFORM_ID) private platformId: any // Inject platform ID
  ) {}

  public addSEO(title: string, description: string): void {
    this.setSpecificTitle(title);
    this.setMetaDescription(description);
  }

  public setSpecificTitle(titlePostfix: string): void {
    const venueName = this.store.selectSnapshot(VenueState.venue)?.name;
    this.title.setTitle(`${venueName} - ${titlePostfix}`);
  }

  public setMetaDescription(content: string): void {
    this.meta.addTag({
      name: 'description',
      content,
    });
  }

  setMetaTags(seo: { [key: string]: any }): void {
    const dynamicTags = this.meta.getTags('property^="restaurant:"') || [];
    dynamicTags.forEach((tag) => this.meta.removeTagElement(tag));
    if (seo['title']) {
      this.title.setTitle(seo['title']);
    }

    Object.entries(seo)
      .filter(([_, value]) => value !== undefined && value !== null)
      .forEach(([key, value]) => {
        if (key.startsWith('og:')) {
          const itempropMap: { [key: string]: string } = {
            'og:title': 'title',
            'og:description': 'description',
            'og:image': 'image',
            'og:logo': 'logo'
          };

          this.meta.updateTag(
            itempropMap[key]
              ? { property: key, itemprop: itempropMap[key], content: value }
              : { property: key, content: value }
          );
        } else if (key === 'canonical') {
          this.updateCanonicalUrl(value);
        } else {
          this.meta.updateTag({ name: key, content: value });
        }
      });
  }

  private updateCanonicalUrl(url: string): void {
    if (isPlatformBrowser(this.platformId)) {
      let link: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`);
      if (link) {
        link.setAttribute('href', url);
      } else {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', url);
        document.head.appendChild(link);
      }
    }
  }
}
