<div class="d-flex justify-content-between rs-list-of-items-in-order__events">
  <mat-checkbox
    *ngIf="showCheckboxes"
    class="rs-list-of-items-in-order__amount"
    [checked]="everyItemInCartIsSelected"
    (change)="selectAllItems($event.checked)"
  >
    {{ amount | rsCartItemsAmount | rsEasternArabicNumerals }}
  </mat-checkbox>

  <a
    rsTriggerClickOnEnter
    tabindex="0"
    class="rs-list-of-items-in-order__order-more"
    [id]="id.Cart.orderMore"
    (click)="goToOrdering()"
  >
    <mat-icon [svgIcon]="addOutlineIcon"></mat-icon>
    {{ 'PAY_NOW.ORDER_MORE' | translate | uppercase }}
  </a>
</div>
<div>
  <ng-template [ngForOf]="items" let-item ngFor>
    <div class="w-100 rs-list-of-items-in-order__item">
      <ng-container
        *ngFor="
          let isThereAnyAvailableOrderMethods of [
            isThereAnyAvailableOrderMethods$ | async
          ]
        "
      >
        <ng-template [ngIf]="!isThereAnyAvailableOrderMethods">
          <rs-banner-alert
            class="d-block w-fit-content mt-3 mb-2"
            rs-rounded="true"
            rs-status="error"
          >
            {{ 'MENUS.unavailable_all_order_types' | translate }}
          </rs-banner-alert>
        </ng-template>

        <ng-container *ngFor="let orderType of [orderType$ | async]">
          <ng-container
            *ngFor="
              let isItemUnavailable of [
                !item.isAvailable ||
                  !isThereAnyAvailableOrderMethods ||
                  (showCheckboxes &&
                    (!item.menuAvailabilityForSelectedDeliveryType ||
                      !item.menuAvailabilityAtSelectedTime) &&
                    orderType)
              ]
            "
          >
            <rs-cart-order-item
              [item]="item"
              [showCheckboxes]="showCheckboxes"
              [availableToChange]="itemCanBeChanged"
              [availableToOrder]="!isItemUnavailable"
              [bundleItemsNames]="bundleItemsNames$ | async"
              [unavailableMessage]="
                item.isAvailable
                  ? item.menuAvailabilityForSelectedDeliveryType
                    ? item.menuAvailabilityAtSelectedTime
                      ? ''
                      : ('MENUS.this_item_is_unavailable_at_the_chosen_time'
                        | translate)
                    : ('MENUS.unavailable_for_orderType'
                      | translate: { orderType: orderType | titlecase })
                  : ('MENUS.unavailable' | translate)
              "
              (amountChanged)="
                $event > 0
                  ? increaseItemQuantity.emit(item)
                  : decreaseItemQuantity.emit(item)
              "
              (clickedOnItem)="handleClickByItem(item)"
              (selected)="selectItem(item.cartItemId || '', $event)"
            ></rs-cart-order-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</div>
