import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { FormBuilder, FormControl } from '@angular/forms';

import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { Country } from 'ngx-mat-intl-tel-input/lib/model/country.model';
import { CountryCode } from 'libphonenumber-js';
import libphonenumber from 'google-libphonenumber';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

import {
  PHONE_DEFAULT_TYPE,
  PREFERRED_COUNTRIES,
} from './phone-input.constants';
import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';
import { PhoneNumber } from 'src/app/_shared/_models/phone-number.model';

@Component({
  selector: 'rs-phone-input',
  styleUrls: ['./phone-input.component.scss'],
  templateUrl: './phone-input.component.html',
})
export class RsPhoneInputComponent {
  @Input() public phoneGroup = this.formBuilder.group({
    phoneNumber: new FormControl(''),
    countryCode: new FormControl(''),
    type: new FormControl(''),
  });

  @Input() hint!: string;
  @Input() label!: string;
  @Input() hasSuccessStatus: boolean = false;
  @Input() successMessage!: string;

  @Input() placeholder: string = '';
  @Input() syncClearPhoneControl?: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;

  @HostBinding('class.w-100') w100Class = true;

  @ViewChild(NgxMatIntlTelInputComponent)
  public phoneInput: NgxMatIntlTelInputComponent = {} as NgxMatIntlTelInputComponent;

  public phoneForm = this.formBuilder.group({
    phoneNumber: new FormControl(),
  });

  public selectedCountry: Country = {} as Country;
  public isInvalid: boolean = false;
  public isValid: boolean = false;
  public isShowPlaceholder: boolean = false;
  public requiredError: boolean = false;
  public isResponsive: boolean = true;

  public readonly angleDown = CommonIcons.AngleDown;
  public readonly success = CommonIcons.Success;
  public readonly preferredCountries = PREFERRED_COUNTRIES;

  private destroy$ = untilDestroyed();

  @Output() public phoneNumber = new EventEmitter<PhoneNumber>();
  @Output() public errorStateChange = new EventEmitter<boolean>();
  @Output() private phoneNumberFieldHasValue = new EventEmitter<boolean>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly formBuilder: FormBuilder,
    private readonly svgIconService: SvgIconService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef
  ) {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.angleDown, this.success],
      NEW_ICONS_DIRECTORY
    );
  }

  public ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.initializeData();
    this.initializeSubscriptions();
  }

  ngAfterViewInit() {
    this.checkPlaceholderState();
    this.addArrowToFlag();

    this.phoneForm.valueChanges
      .pipe(this.destroy$())
      .subscribe(() => this.checkPlaceholderState());
  }

  private initializeSubscriptions(): void {
    this.phoneGroup.valueChanges
      .pipe(this.destroy$())
      .subscribe(() => this.setIncomingPhoneNumber());

    this.phoneForm
      .get('phoneNumber')
      ?.valueChanges.pipe(this.destroy$())
      .subscribe(() =>
        setTimeout(() =>
          this.errorStateChange.emit(this.phoneForm.get('phoneNumber')?.valid)
        )
      );
  }

  private initializeData(): void {
    this.phoneForm = this.formBuilder.group({
      phoneNumber: new FormControl(this.getFullPhoneNumber()),
    });

    this.checkValidity();
  }

  private checkValidity(): void {
    this.phoneForm.valid &&
      !!this.phoneForm.value.phoneNumber &&
      (this.isValid = true);
  }

  private addArrowToFlag(): void {
    const inputElement = this.elementRef.nativeElement.querySelector(
      'ngx-mat-intl-tel-input .ngx-mat-tel-input-container input'
    );

    if (inputElement) {
      this.renderer.setAttribute(inputElement, 'aria-label', 'Phone number');
    }

    const countryCode = this.elementRef.nativeElement.querySelector(
      'ngx-mat-intl-tel-input .ngx-mat-tel-input-container button span.mat-button-wrapper span.country-selector-code'
    );

    const getArrowIcon = this.elementRef.nativeElement.querySelector(
      '.rs-phone-input__icon'
    );

    const ngxMatInput = this.elementRef.nativeElement.querySelector(
      'ngx-mat-intl-tel-input .ngx-mat-tel-input-container button span.mat-button-wrapper'
    );

    this.renderer.insertBefore(ngxMatInput, getArrowIcon, countryCode);
  }

  private checkPlaceholderState(): void {
    if (this.placeholder) {
      this.phoneInput.inputPlaceholder = this.placeholder;
    }
  }

  public setInputOnFocus(telInput: NgxMatIntlTelInputComponent): void {
    const telElementRef = (telInput as any).elRef as ElementRef;
    const telHtmlElement = telElementRef.nativeElement as HTMLElement;
    const telInputElement = telHtmlElement.querySelector(
      '.rs-phone-control__input'
    ) as HTMLElement;

    telInputElement.focus();
  }

  public reactOnInput(): void {
    this.isValid = false;

    if (this.syncClearPhoneControl) {
      this.phoneNumberFieldHasValue.emit(!!this.phoneInput.phoneNumber);

      !this.phoneInput.phoneNumber &&
        this.phoneNumber.emit({
          phoneNumber: '',
          countryCode: '+' + this.selectedCountry.dialCode,
          type: PHONE_DEFAULT_TYPE,
        });
    }

    if (this.phoneForm.valid && !!this.phoneForm.value.phoneNumber) {
      this.isValid = true;
      this.emitPhoneNumber(this.phoneForm.value.phoneNumber);
    }

    this.checkFormErrorStates();
  }

  public reactOnClick(): void {
    this.checkRequiredError();
  }

  public reactOnCountryChanged(country: Country): void {
    this.selectedCountry = country;
  }

  private setIncomingPhoneNumber(): void {
    const phoneFormValue = this.phoneGroup.value;

    if (!!phoneFormValue.countryCode && !!phoneFormValue.phoneNumber) {
      this.phoneForm.patchValue({
        phoneNumber: phoneFormValue.countryCode + phoneFormValue.phoneNumber,
      });
    } else {
      this.phoneInput.reset();
    }
  }

  private emitPhoneNumber(phoneNumber: string): void {
    const dialCode = '+' + this.selectedCountry.dialCode;
    const localPhoneNumber = phoneNumber.substring(dialCode.length);
    const PhoneNumber: PhoneNumber = {
      phoneNumber: localPhoneNumber,
      countryCode: dialCode,
      type: PHONE_DEFAULT_TYPE,
    };

    this.phoneNumber.emit(PhoneNumber);
  }

  private checkFormErrorStates(): void {
    try {
      const PhoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const number = PhoneNumberUtil.parseAndKeepRawInput(
        this.phoneGroup.value.phoneNumber || '',
        this.selectedCountry.iso2.toUpperCase() as CountryCode
      );

      if (!PhoneNumberUtil.isValidNumber(number)) {
        this.isInvalid = true;
        this.phoneGroup
          .get('phoneNumber')
          ?.setErrors({ validatePhoneNumber: true });
      }
    } catch (e) {
      console.log('Invalid phone');
    }

    if (this.phoneForm.invalid && this.phoneGroup.valid) {
      this.isInvalid = true;
      this.phoneGroup
        .get('phoneNumber')
        ?.setErrors({ validatePhoneNumber: true });
    }

    this.checkRequiredError();
  }

  private checkRequiredError(): void {
    if (this.required && !this.phoneInput.phoneNumber) {
      this.requiredError = true;
      this.isInvalid = true;
      this.phoneGroup.get('phoneNumber')?.setErrors({ required: true });
    } else if (this.phoneInput.phoneNumber) {
      const errors = this.phoneGroup.get('phoneNumber')?.errors;
      this.requiredError = false;
      delete errors?.['required'];
      this.isInvalid = true;
      this.phoneGroup.get('phoneNumber')?.setErrors(errors || null);
    } else {
      this.isValid = false;
      this.isInvalid = false;
    }
  }

  private getFullPhoneNumber(): string {
    const phoneGroupValue = this.phoneGroup.value;

    if (
      phoneGroupValue.phoneNumber &&
      phoneGroupValue.countryCode?.startsWith('+')
    ) {
      let countryCode = '';

      if (this.selectedCountry.dialCode) {
        countryCode = '+' + this.selectedCountry.dialCode;
      } else if (phoneGroupValue.countryCode) {
        countryCode = phoneGroupValue.countryCode;
      }

      return `${countryCode}${phoneGroupValue.phoneNumber}`;
    }

    return '';
  }

  public get isPhoneNumberInvalid(): boolean {
    return (
      !this.phoneInput.value &&
      !!this.phoneInput?.phoneNumber &&
      !this.phoneGroup.get('phoneNumber')?.valid &&
      !!this.phoneForm.get('phoneNumber')?.dirty
    );
  }

  public get getPhoneNumber(): any {
    return this.phoneForm.controls.phoneNumber.value;
  }
}
