import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[rsTriggerClickOnEnter]',
  standalone: true,
})
export class TriggerClickOnEnterDirective {
  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const predefinedTabIndex: number | null =
      this.elementRef.nativeElement.getAttribute('tabindex');

    predefinedTabIndex !== null &&
      this.renderer.setAttribute(
        this.elementRef.nativeElement,
        'tabindex',
        '0'
      );
  }

  @HostListener('keydown', ['$event'])
  private onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.elementRef.nativeElement.click();
      event.stopPropagation();
    }
  }
}
