<form
  *ngIf="showForgotPassword; else rsLoginForm"
  class="d-flex flex-column"
  [formGroup]="recoveryPasswordGroup"
  (submit)="sendRecoveryLink()"
>
  <rs-input
    rs-input-style="email"
    [required]="true"
    [screenReaderOnlyLabel]="true"
    [control]="emailControl"
    [placeholder]="'SIGN_UP_SCREEN.EMAIL' | translate"
    [label]="'SIGN_UP_SCREEN.EMAIL' | translate"
    [injectedSuccess]="(injectedSuccess | async) || ''"
    (onInput)="resetRecoveryLinkSent()"
  >
  </rs-input>

  <button
    [id]="passwordResetRecoveryId"
    rs-style="bold"
    type="submit"
    [disabled]="recoveryPasswordGroup.invalid"
    *ngIf="!isRecoveryLinkSent"
  >
    <span>
      {{ 'RESET_PASSWORD.SEND_RECOVERY_LINK' | translate | uppercase }}
    </span>
  </button>
</form>

<ng-template #rsLoginForm>
  <form [formGroup]="loginForm" class="d-flex flex-column">
    <rs-input
      rs-input-style="default"
      inputType="email"
      [required]="true"
      [placeholder]="'WELCOME_SCREEN.Phone Number or Email' | translate"
      [screenReaderOnlyLabel]="true"
      [label]="'WELCOME_SCREEN.Phone Number or Email' | translate"
      [control]="emailOrPhoneControl"
      (onKeyDownEnter)="login()"
    ></rs-input>

    <rs-input
      rs-input-style="password"
      [control]="passwordControl"
      [required]="true"
      [placeholder]="'WELCOME_SCREEN.Password' | translate"
      [screenReaderOnlyLabel]="true"
      [label]="'WELCOME_SCREEN.Password' | translate"
      (onKeyDownEnter)="login()"
    ></rs-input>
    <button
      rs-style="bold"
      [disabled]="emailOrPhoneControl?.errors || passwordControl?.errors"
      class="w-100 m-1"
      (click)="login()"
    >
      <span>{{ 'WELCOME_SCREEN.CONTINUE' | translate }}</span>
    </button>
    <button
      rs-style="text"
      class="w-100 m-1"
      (click)="changeToForgotPassword()"
    >
      <span>{{ 'LOG_IN.FORGOT_PASSWORD' | translate | uppercase }}</span>
    </button>
  </form>
</ng-template>
