import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import { TextMaskConfig } from 'angular2-text-mask';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import moment from 'moment';
import { debounceTime } from 'rxjs';

import {
  emailValidator,
  emptyOrNoCharactersValidator,
  timeValidator,
} from './_validations/validators';
import { DefaultOptions, InputOptions, InputPlaceholder } from './input-type';

import { PaymentValidator } from '../../_validators/payment.validation';
import { CardBrand } from '../../_enums/card.enum';
import { getPaymentCardPattern } from '../../_utils/payment-card';
import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';
import { untilDestroyed } from '../../_utils/until-destroyed';
import { CVV_NUMBER_MASK_CONFIG } from '../../_constants/card.constants';

import { PaymentCardPattern } from 'src/app/profile/_interfaces/payment.model';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

const currencyOptions: Partial<CurrencyMaskConfig> = {
  thousands: ',',
  decimal: '.',
  allowNegative: false,
  align: 'left',
};

@Component({
  selector: 'rs-input',
  styleUrls: ['./input.component.scss'],
  templateUrl: './input.component.html',
  animations: [
    trigger('dropdown', [
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class RsInputComponent implements AfterViewInit {
  @Input('rs-input-style') set inputStyle(inputStyle: InputOptions | string) {
    this.type = inputStyle as InputOptions;
  }
  @Input() control: any = new FormControl();
  @Input() placeholder: string = '';
  @Input() inputType: DefaultOptions | string = DefaultOptions.Text;
  @Input() label!: string;
  @Input() hint!: string;
  @Input() successMessage!: string;
  @Input() hasClearButton: boolean = true;
  @Input() hasSuccessStatus: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() textMask!: TextMaskConfig;
  @Input() maskValidator: ValidatorFn[] = [];
  @Input() maskErrors!: Object;
  @Input() injectedWarning!: string;
  @Input() injectedSuccess!: string;
  @Input() min!: number | string | undefined;
  @Input() max!: number | string | undefined;
  @Input() public iconType!: CommonIcons;
  @Input() public screenReaderOnlyLabel: boolean = false;

  @HostBinding('class.w-100') w100Class = true;

  @ViewChild('defaultInput') defaultInput!: ElementRef<HTMLInputElement>;
  @ViewChild('emailInput') emailInput!: ElementRef<HTMLInputElement>;
  @ViewChild('websiteInput') websiteInput!: ElementRef<HTMLInputElement>;
  @ViewChild('salesInput') salesInput!: ElementRef<HTMLInputElement>;
  @ViewChild('cardInput') cardInput!: ElementRef<HTMLInputElement>;
  @ViewChild('maskedInput') maskedInput!: ElementRef<HTMLInputElement>;
  @ViewChild('cvvInput') cvvInput!: ElementRef<HTMLInputElement>;

  public isActive: boolean = false;
  public canBeRightToLeft: boolean = true;
  public form: FormGroup = new FormGroup({});
  public type: InputOptions = InputOptions.Default;
  public currencyOptions: Partial<CurrencyMaskConfig> = {
    prefix: '',
    ...currencyOptions,
  };
  public currentErrorType: any = { invalid: true };
  public isWebsiteDropdownOpen: boolean = false;
  public InputOptions = InputOptions;
  public DefaultOptions = DefaultOptions;
  public formControl: any = new FormControl();
  public isInvalid: boolean = false;
  public isValid: boolean = false;
  public ifVisibilityOff: boolean = true;
  public cardBrand!: CardBrand;
  public convertedTimeMin!: string;
  public convertedTimeMax!: string;
  public websiteOptions: string[] = ['https://', 'http://'];
  public websiteOption: string = this.websiteOptions[0];
  public cardCVVConfig: TextMaskConfig = CVV_NUMBER_MASK_CONFIG;

  public readonly mastercard = CommonIcons.Mastercard;
  public readonly dollar = CommonIcons.Dollar;
  public readonly mail = CommonIcons.Mail;
  public readonly success = CommonIcons.Success;
  public readonly error = CommonIcons.Error;
  public readonly angleDown = CommonIcons.AngleDown;
  public readonly visibility_off = CommonIcons.Hide;
  public readonly visibility = CommonIcons.Show;

  private triggeredChange: boolean = false;
  private activeInput!: ElementRef<HTMLInputElement>;
  private readonly destroyed$ = untilDestroyed();
  private readonly cvvValidator: ValidatorFn[] = [
    PaymentValidator.securityCodeRequired,
    PaymentValidator.cvvLengthValidator,
    Validators.pattern(/^[0-9\s]*$/),
  ];

  @Output() onBlur: EventEmitter<Event> = new EventEmitter();
  @Output() onFocus: EventEmitter<Event> = new EventEmitter(); // 'onFocus' will emit after we perform a validity reset upon the focus event.
  @Output() onInput: EventEmitter<Event> = new EventEmitter(); // 'onInput' will emit after we perform a validity reset and if type is Card, it will check the card type upon the input event.
  @Output() onKeyDownEnter: EventEmitter<Event> = new EventEmitter(); // 'onKeyDownEnter' will emit after we call event.preventDefault() so that other elements do not get triggered on the keydown.enter event.
  @Output() controlChange = new EventEmitter<FormControl<any>>();
  @Output() injectedSuccessChange = new EventEmitter<string>();
  @Output() injectedWarningChange = new EventEmitter<string>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly svgIconService: SvgIconService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['control'] && !changes['control'].firstChange) {
      this.formControl.setValue(this.controlValue, { emitEvent: false });
    }

    if (changes['injectedWarning'] && !changes['injectedWarning'].firstChange) {
      this.isInvalid = true;
      this.isValid = false;
    } else if (
      changes['injectedSuccess'] &&
      !changes['injectedSuccess'].firstChange
    ) {
      this.isValid = true;
      this.isInvalid = false;
    }
  }

  ngOnInit(): void {
    this.registerIcons();

    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.type === InputOptions.Password) {
      this.control.statusChanges
        .pipe(this.destroyed$(), debounceTime(150))
        .subscribe(() => {
          const errors = this.control.errors;

          this.formControl.setErrors(errors ? errors : null);

          !!this.formControlValue && this.checkFormErrorStates();
        });
    }

    this.control.valueChanges
      .pipe(this.destroyed$(), debounceTime(150))
      .subscribe(() => {
        if (!this.triggeredChange) {
          this.triggeredChange = true;
          this.formControl.setValue(this.controlValue, { emitEvent: false });

          if (this.type === InputOptions.Password) {
            this.form.setErrors(this.control.errors);
          }

          setTimeout(() => {
            this.triggeredChange = false;
          }, 150);
        }
      });

    switch (this.type) {
      case InputOptions.Email:
        this.setupEmailInput();
        break;
      case InputOptions.Sales:
        this.setupSalesInput();
        break;
      case InputOptions.Card:
        this.setupCardInput();
        break;
      case InputOptions.Website:
        this.setupWebsiteInput();
        break;
      case InputOptions.Password:
        this.setupPasswordInput();
        break;
      case InputOptions.Masked:
        this.setMaskedInput();
        break;
      case InputOptions.CVV:
        this.setCVVInput();
        break;
      default:
        this.setupDefaultInput();
        break;
    }

    this.subscribeToFormControl();
  }

  ngAfterViewInit(): void {
    switch (this.type) {
      case InputOptions.Email:
        this.activeInput = this.emailInput;
        break;
      case InputOptions.Sales:
        this.activeInput = this.salesInput;
        break;
      case InputOptions.Card:
        this.activeInput = this.cardInput;
        break;
      case InputOptions.Website:
        this.activeInput = this.websiteInput;
        break;
      case InputOptions.Masked:
        this.activeInput = this.maskedInput;
        break;
      case InputOptions.CVV:
        this.activeInput = this.cvvInput;
        break;
      default:
        this.activeInput = this.defaultInput;
        break;
    }
  }

  private setupEmailInput(): void {
    this.canBeRightToLeft = false;
    this.currentErrorType = { invalidEmail: true };
    this.iconType = CommonIcons.Mail;
    this.placeholder ||= InputPlaceholder.Email;
    const validators = this.required
      ? [Validators.required, emailValidator()]
      : [emailValidator()];
    this.formControl = new FormControl(this.controlValue, validators);
  }

  private setupSalesInput(): void {
    let parsedValue = this.controlValue
      ? Number(this.controlValue) / 100
      : null;
    this.iconType = CommonIcons.Dollar;
    this.placeholder ||= InputPlaceholder.Sales;
    this.formControl = new FormControl(parsedValue, Validators.nullValidator);
  }

  private setupCardInput(): void {
    this.canBeRightToLeft = false;
    this.iconType = CommonIcons.Mastercard;
    this.placeholder ||= InputPlaceholder.Card;
    const cardValidator = this.required
      ? [Validators.required, PaymentValidator.cardNumberValidator]
      : [PaymentValidator.cardNumberValidator];
    this.controlValue && this.getCardType();
    this.formControl = new FormControl(this.controlValue, cardValidator);
  }

  private setupWebsiteInput(): void {
    this.canBeRightToLeft = false;
    this.placeholder ||= InputPlaceholder.Website;
    this.formControl = new FormControl(
      this.controlValue,
      this.defaultValidators()
    );
  }

  private setupPasswordInput(): void {
    this.placeholder ||= InputPlaceholder.Password;
    this.hasClearButton = false;
    this.formControl = new FormControl(
      this.controlValue,
      this.defaultValidators()
    );
  }

  private setMaskedInput(): void {
    const customValidator = this.required
      ? [Validators.required, ...this.maskValidator]
      : [...this.maskValidator];

    this.formControl = new FormControl(this.controlValue, customValidator);
  }

  private setCVVInput(): void {
    const customValidator = this.required
      ? [Validators.required, ...this.cvvValidator]
      : [...this.cvvValidator];

    this.formControl = new FormControl(this.controlValue, customValidator);
  }

  private setupDefaultInput(): void {
    if (this.inputType === DefaultOptions.Number) {
      this.setupNumberInput();
    } else if (this.inputType === DefaultOptions.Time) {
      this.setupTimeInput();
    } else {
      this.formControl = new FormControl(
        this.controlValue,
        this.defaultValidators()
      );
    }
  }

  private setupNumberInput(): void {
    const parsedMin: number = Number(this.min);
    const parsedMax: number = Number(this.max);
    const numberValidator = this.required
      ? [
          Validators.required,
          Validators.min(parsedMin),
          Validators.max(parsedMax),
        ]
      : [Validators.min(parsedMin), Validators.max(parsedMax)];
    this.formControl = new FormControl(this.controlValue, numberValidator);
  }

  private setupTimeInput(): void {
    const parsedMin: string = this.min ? this.min.toString() : '00:00';
    const parsedMax: string = this.max ? this.max.toString() : '23:59';
    this.convertedTimeMin = this.convertTo12Hours(parsedMin);
    this.convertedTimeMax = this.convertTo12Hours(parsedMax);
    const timeValidators = this.required
      ? [Validators.required, timeValidator(parsedMin, parsedMax)]
      : [timeValidator(parsedMin, parsedMax)];
    this.formControl = new FormControl(this.controlValue, timeValidators);
  }

  private subscribeToFormControl(): void {
    this.formControl.valueChanges.pipe(this.destroyed$()).subscribe(() => {
      this.clearWarningSuccess();

      this.control.setValue(this.formControl.value);

      if (!this.isActive) {
        this.onOutsideClick();
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  private get controlValue(): string | number | null {
    return this.control?.value;
  }

  private get formControlValue(): AbstractControl | null {
    return this.formControl?.value;
  }

  private defaultValidators(): ValidatorFn[] | null {
    return this.required
      ? [Validators.required, emptyOrNoCharactersValidator()]
      : [Validators.nullValidator];
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [
        this.iconType,
        this.mastercard,
        this.dollar,
        this.mail,
        this.success,
        this.error,
        this.angleDown,
        this.visibility,
        this.visibility_off,
      ],
      NEW_ICONS_DIRECTORY
    );
  }

  public clearValue(): void {
    this.formControl.reset();
    this.onInput.emit();

    setTimeout(() => {
      this.activeInput?.nativeElement.focus();
    }, 50);
  }

  public reactOnFocus(): void {
    this.resetValidity();

    this.onFocus.emit();
  }

  public reactOnInput(): void {
    this.resetValidity();

    if (this.type === InputOptions.Card) {
      this.getCardType();
    }

    this.onInput.emit();
  }

  private clearWarningSuccess(): void {
    this.isInvalid = false;
    this.isValid = false;

    this.injectedWarning = '';
    this.injectedSuccess = '';

    this.injectedWarningChange.emit(this.injectedWarning);
    this.injectedSuccessChange.emit(this.injectedSuccess);
  }

  public reactOnKeyDownEnter(event: Event): void {
    event.preventDefault();
    this.onKeyDownEnter.emit();
  }

  public reactOnKeyDownTab(): void {
    this.checkFormErrorStates();
  }

  private checkFormErrorStates(): void {
    this.isValid = false;
    this.isInvalid = false;

    if (this.injectedSuccess || this.injectedWarning) {
      if (this.injectedSuccess) {
        this.isValid = true;
        this.isInvalid = false;
      } else if (this.injectedWarning) {
        this.isValid = false;
        this.isInvalid = true;
      }
    } else if (this.formControl?.invalid) {
      this.isInvalid = true;
    } else {
      this.formControlValue && this.hasSuccessStatus && (this.isValid = true);

      this.type === InputOptions.Card && this.getCardType();
    }
  }

  private getCardType(): void {
    if (this.formControl.value && this.formControl.value.length > 2) {
      const cardType: PaymentCardPattern | null = getPaymentCardPattern(
        this.formControl.value
      );

      switch (cardType?.type.toUpperCase()) {
        case CardBrand.Mastercard.toUpperCase():
          this.cardBrand = CardBrand.Mastercard;
          break;
        case CardBrand.Visa.toUpperCase():
          this.cardBrand = CardBrand.Visa;
          break;
        case CardBrand.JCB.toUpperCase():
          this.cardBrand = CardBrand.JCB;
          break;
        case CardBrand.Discover.toUpperCase():
          this.cardBrand = CardBrand.Discover;
          break;
        case CardBrand.Amex.toUpperCase():
          this.cardBrand = CardBrand.Amex;
          break;
        case CardBrand.DinersClub.toUpperCase():
          this.cardBrand = CardBrand.DinersClub;
          break;
        case 'DINERS_CLUB_INTERNATIONALS':
          this.cardBrand = CardBrand.DinersClub;
          break;
        default:
          this.cardBrand = CardBrand.Unknown;
          break;
      }
    }
  }

  public changeVisibility(): void {
    this.ifVisibilityOff = !this.ifVisibilityOff;
  }

  private convertTo12Hours(time: string): string {
    return moment(time, 'HH:mm').format('h:mm A');
  }

  public toggleWebsiteDropdown(): void {
    this.isActive = true;
    this.isWebsiteDropdownOpen = !this.isWebsiteDropdownOpen;
  }

  public selectedWebsiteOption(option: string) {
    this.websiteOption = option;
    this.isWebsiteDropdownOpen = false;
  }

  public onOutsideClick(): void {
    this.checkFormErrorStates();
    this.isActive = false;
    this.isWebsiteDropdownOpen = false;
    this.onBlur.emit();
  }

  public get applyPadding(): boolean {
    return (
      (this.hasClearButton && this.controlValue !== null) ||
      this.isValid ||
      this.isInvalid
    );
  }

  public get showClearButton(): boolean {
    return (
      this.hasClearButton &&
      this.isControlValueNotEmpty() &&
      this.isActive &&
      !this.isValid &&
      !this.isInvalid
    );
  }

  private isControlValueNotEmpty(): boolean {
    const value = this.controlValue;

    return !(
      value == null ||
      value === '' ||
      (typeof value === 'string' && value.trim() === '') ||
      (this.type === 'card' &&
        !this.isFirstCharacterNumberOrSpace(value.toString()))
    );
  }

  private isFirstCharacterNumberOrSpace(str: string): boolean {
    const regex = /^[0-9\s]/;

    return regex.test(str);
  }

  private resetValidity(): void {
    this.isActive = true;
    this.isValid = false;
    this.isInvalid = false;
  }
}
