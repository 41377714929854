<rs-dietary-restrictions-list-wrapper
  *ngIf="
    (defaultAllergens | rsExistsAllergenType: AllergenType.AllergenFree) ||
    (!showOnlyDefaultAllergens &&
      (customAllergens | rsExistsAllergenType: AllergenType.AllergenFree))
  "
>
  <span rs-title-data role="heading" tabindex="0">
    {{ 'DIETARY_RESTRICTIONS.free' | translate | titlecase }}
  </span>
  <ng-container rs-tooltip-data>
    {{ 'DIETARY_RESTRICTIONS.free information' | translate }}
  </ng-container>

  <rs-dietary-restrictions-button
    *ngFor="let allergen of defaultAllergens | rsGetAllergensFree"
    [allergen]="allergen"
    [selected]="selectedDefaultAllergens | rsIncludesAllergen: allergen.value"
    [disabled]="
      selectedDefaultAllergens
        | rsIncludesOppositeByMeaningAllergen: allergen.value
    "
    (toggled)="updateSelectedAllergens(allergen.value, $event)"
  ></rs-dietary-restrictions-button>

  <ng-container *ngIf="!showOnlyDefaultAllergens">
    <rs-dietary-restrictions-button
      *ngFor="let allergen of customAllergens | rsGetAllergensFree"
      [allergen]="allergen"
      [selected]="selectedCustomAllergens | rsIncludesAllergen: allergen.value"
      [disabled]="
        selectedCustomAllergens
          | rsIncludesOppositeByMeaningAllergen: allergen.value
      "
      (toggled)="updateSelectedAllergens(allergen.value, $event, true)"
    ></rs-dietary-restrictions-button>
  </ng-container>

  <!--
    TODO: uncomment it when 'Other +' functionality is available
    <ng-template
      [ngIf]="
        customAllergens
          | rsGetAllergensFree
          | rsGetNotSelectedAllergens: selectedCustomAllergens
      "
      let-notSelectedCustomAllergens
    >
      <rs-add-new-allergen
        [allergens]="notSelectedCustomAllergens"
        (added)="updateSelectedAllergens($event.value, true, true)"
      ></rs-add-new-allergen>
    </ng-template>
  -->
</rs-dietary-restrictions-list-wrapper>

<rs-dietary-restrictions-list-wrapper
  *ngIf="
    (defaultAllergens | rsExistsAllergenType: AllergenType.AllergenTrace) ||
    (!showOnlyDefaultAllergens &&
      (customAllergens | rsExistsAllergenType: AllergenType.AllergenTrace))
  "
>
  <span rs-title-data role="heading" tabindex="0">
    {{ 'DIETARY_RESTRICTIONS.traces' | translate | titlecase }}
  </span>
  <ng-container rs-tooltip-data>
    {{ 'DIETARY_RESTRICTIONS.traces information' | translate }}
  </ng-container>

  <rs-dietary-restrictions-button
    *ngFor="let allergen of defaultAllergens | rsGetAllergensTrace"
    [allergen]="allergen"
    [selected]="selectedDefaultAllergens.includes(allergen)"
    [disabled]="
      selectedDefaultAllergens
        | rsIncludesOppositeByMeaningAllergen: allergen.value
    "
    (toggled)="updateSelectedAllergens(allergen.value, $event)"
  ></rs-dietary-restrictions-button>

  <ng-container *ngIf="!showOnlyDefaultAllergens">
    <rs-dietary-restrictions-button
      *ngFor="let allergen of customAllergens | rsGetAllergensTrace"
      [allergen]="allergen"
      [selected]="selectedCustomAllergens | rsIncludesAllergen: allergen.value"
      [disabled]="
        selectedCustomAllergens
          | rsIncludesOppositeByMeaningAllergen: allergen.value
      "
      (toggled)="updateSelectedAllergens(allergen.value, $event, true)"
    ></rs-dietary-restrictions-button>
  </ng-container>

  <!--
    TODO: uncomment it when 'Other +' functionality is available
    <ng-template
      [ngIf]="
        customAllergens
          | rsGetAllergensTrace
          | rsGetNotSelectedAllergens: selectedCustomAllergens
      "
      let-notSelectedCustomAllergens
    >
      <rs-add-new-allergen
        [allergens]="notSelectedCustomAllergens"
        (added)="updateSelectedAllergens($event.value, true, true)"
      ></rs-add-new-allergen>
    </ng-template>
  !-->
</rs-dietary-restrictions-list-wrapper>
<!--
  TODO uncomment later when Spicy Level is available in BO
<rs-dietary-restrictions-list-wrapper
  *ngIf="defaultAllergens | rsExistsAllergenType: AllergenType.SpicyLevel"
>
  <span rs-title-data>
    {{ 'DIETARY_RESTRICTIONS.spicy level' | translate | titlecase }}
  </span>
  <rs-spicy-level-button
    *ngFor="let spicyLevel of defaultAllergens | rsGetSpicyLevels"
    [level]="spicyLevel"
    [selected]="selectedDefaultAllergens.includes(spicyLevel)"
    (toggled)="updateSelectedAllergens(spicyLevel, $event)"
  ></rs-spicy-level-button>
</rs-dietary-restrictions-list-wrapper> -->
