import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { CountryFlagComponent } from 'src/app/_shared/_components/country/country-flag.component';
import { ItemAllergensComponent } from 'src/app/_shared/_components/allergens-dietary-restrictions/item-allergens/item-allergens/item-allergens.component';

import { ItemData } from 'src/app/_shared/_interfaces/item.model';
import { WineReviewsSummaryPipe } from 'src/app/_shared/_components/wine-reviews/wine-reviews-summary.pipe';
import { GetCountryNameByCode3Pipe } from 'src/app/_shared/_components/country/get-country-code.pipe';
import { Store } from '@ngxs/store';
import { SEOService } from '../../../_services/seo.service';
import { VenueState } from '../../_ngxs/venue.state';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'rs-item-description',
  templateUrl: './rs-item-description.component.html',
  styleUrls: ['./rs-item-description.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    WineReviewsSummaryPipe,
    CountryFlagComponent,
    GetCountryNameByCode3Pipe,
    ItemAllergensComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsItemDescriptionComponent implements OnInit {
  @Input() itemData: ItemData | null = null;
  @Input() price: string | undefined = undefined;
  @Input() calories: number | undefined = undefined;
  @Input() itemHasAllergens: boolean | null = false;

  constructor(
    private readonly store: Store,
    private readonly seoService: SEOService,
    private router: Router,
    private readonly meta: Meta,
  ) {}
  ngOnInit(): void {
    this.setMetaTags();
  }
  addSEO(): void {
    const venueName = this.store.selectSnapshot(VenueState.venue)?.name;
    this.seoService.addSEO(
      `${this.itemData?.item.customerFacingName || this.itemData?.item.name}`,
      `${venueName} | ${
        this.itemData?.item.customerFacingName
      }: ${this.itemData?.item.description}`
    );
  }
  setMetaTags() {
    const venue = this.store.selectSnapshot(VenueState.venue);
    const domain = environment.domain;

    const seo = {
      title: `${venue?.name} - Menu | ${this.itemData?.item.customerFacingName || this.itemData?.item.name}`,
      description: `${venue?.name} ${
        this.itemData?.item.customerFacingName || this.itemData?.item.name
      }: ${this.itemData?.item.description}`,
      keywords: `${venue?.name}, ${this.itemData?.item.customerFacingName || this.itemData?.item.name},  ${venue?.address.address1}, ${venue?.address.city}, ${venue?.address.zipCode}, ${venue?.address.country}`,
      canonical: `https://${domain}${this.router.url}`,
      'og:type': 'restaurant.menu_item',
      'og:logo': venue?.logo,
      'og:title': `${venue?.name} - ${this.itemData?.item.customerFacingName || this.itemData?.item.name}`,
      'og:description': `${venue?.name} ${this.itemData?.item.customerFacingName || this.itemData?.item.name}: ${this.itemData?.item.description}`,
      'og:url': `https://${domain}${this.router.url}`,
      'og:image': `${this.itemData?.item.image}`,
      'og:image:secure_url':  `${this.itemData?.item.image}`,
      'restaurant:menu_item:name': `${venue?.name} - ${this.itemData?.item.customerFacingName || this.itemData?.item.name}`,
      'restaurant:menu_item:description': `${venue?.name} ${this.itemData?.item.customerFacingName || this.itemData?.item.name}: ${this.itemData?.item.description}`,
      'restaurant:menu_item:image': `${this.itemData?.item.image}`,
      // 'restaurant:menu_item:price': `${this.itemData?.item.price}`,
      'restaurant:menu_item:currency': `USD`,
      'restaurant:contact_info:street_address': `${venue?.address.address1}`,
      'restaurant:contact_info:locality': `${venue?.address.city}`,
      'restaurant:contact_info:region': `${venue?.address.state}`,
      'restaurant:contact_info:postal_code': `${venue?.address.zipCode}`,
      'restaurant:contact_info:country_name': `${venue?.address.country}`,
    };

    if (venue?.name) {
      this.seoService.setMetaTags(seo);
      this.itemData?.item?.prices.forEach((size) => {
        this.meta.addTag({
          property: 'restaurant:menu_item:variation',
          content: (size.sizeName === 'SINGLE_PRICE') ? 'Price': size.sizeName
        }, false);
        this.meta.addTag({
          property: 'restaurant:menu_item:price',
          content: (size.price.amount / 100).toFixed(2)
        }, false);
      });
    }
  }
}
