import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { GooglePayButtonModule } from '@google-pay/button-angular';
import { Store } from '@ngxs/store';
import { filter, Observable } from 'rxjs';
import { parsePhoneNumber } from 'libphonenumber-js';

import { SharedModule } from '../_modules/shared.module';

import { OrderState } from '../_ngxs/order.state';
import { SaveUserName } from '../_ngxs/authentication.actions';
import { SetGuestUserData } from '../_ngxs/cart.actions';
import { SetGooglePay } from '../_ngxs/order.actions';

import { untilDestroyed } from '../_utils/until-destroyed';

@Component({
  selector: 'rs-google-pay',
  standalone: true,
  imports: [SharedModule, GooglePayButtonModule],
  templateUrl: './google-pay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GooglePayComponent {
  @Input() buttonType: google.payments.api.ButtonType = 'pay';
  @Input() set venueName(value: string) {
    this.googlePaymentRequest.merchantInfo.merchantName = value;
  }
  @Input() set totalPrice(value: string) {
    this.googlePaymentRequest.transactionInfo.totalPrice = value;
  }

  public googlePaymentRequest: google.payments.api.PaymentDataRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    callbackIntents: ['PAYMENT_AUTHORIZATION'],
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['AMEX', 'VISA', 'MASTERCARD'],
          billingAddressRequired: true,
          billingAddressParameters: {
            format: 'FULL',
            phoneNumberRequired: true,
          },
        },
        tokenizationSpecification: {
          type: 'DIRECT',
          parameters: {
            protocolVersion: 'ECv2',
            publicKey:
              'BJpMaGd/LbBEye4MO1atM+etj7o3/VOZC3XukMeLtAaQW6kbaLbbrTjkhpmoLRVKqKdceFHU82w25cRebWQKTHI=',
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: 'BCR2DN4T6TNOTDDS',
      merchantName: '',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: '',
      currencyCode: 'USD',
      countryCode: 'US',
    },
  };

  public readonly orderStatus$: Observable<string | null> = this.store.select(
    OrderState.status
  );
  public readonly destroyed$ = untilDestroyed();

  @Output() payClicked = new EventEmitter<void>();

  constructor(private readonly store: Store) {}

  public onLoadPaymentData(event: any) {
    console.log(`onLoadPaymentData`, event);
  }

  public onError = (event: ErrorEvent): void => {
    this.consoleError(event.error);
  };

  public consoleError = (event: any): void => {
    console.error('error', event);
  };

  // @ts-ignore
  public onPaymentDataAuthorized: google.payments.api.PaymentAuthorizedHandler =
    paymentData => {

      const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
      const googlePay = JSON.parse(paymentToken);

      const userInfo = {
        name: `${paymentData.paymentMethodData.info?.billingAddress?.name} `,
        phone: {
          phoneNumber: parsePhoneNumber(
            paymentData.paymentMethodData.info?.billingAddress?.phoneNumber ||
              ''
          ).nationalNumber,
          countryCode: `+${
            parsePhoneNumber(
              paymentData.paymentMethodData.info?.billingAddress?.phoneNumber ||
                ''
            ).countryCallingCode
          }`,
        },
      };

      // @ts-ignore
      this.orderStatus$.subscribe(status => {
        this.store.dispatch([
          new SaveUserName(userInfo.name),
          new SetGuestUserData(userInfo, true),
          new SetGooglePay(googlePay),
        ]);
        this.payClicked.emit();
        if (status && status === 'success') {
          return {
            transactionState: 'SUCCESS',
          };
        } else if (status && status !== 'success') {
          return;
        }
      });
    };
}
