<ng-container *ngIf="orderType$ | async as orderType">
  <ng-container *ngIf="selectedOrderPeriod$ | async as selectedOrderPeriod">
    <ng-container *ngIf="items$ | async; let items">
      <ng-container *ngFor="let isLoggedIn of [isLoggedIn$ | async]">
        <div
          class="d-flex justify-content-center w-100 h-100 overflow-hidden rs-pay-now rs-fit-content"
        >
          <div class="col-7 p-0 overflow-scroll">
            <app-dialog-header
              [showOnlyLogoLeft]="true"
              [backButtonId]="id.payNowDesktopPopup.backButton"
            ></app-dialog-header>

            <div class="rs-pay-now__items mb-3">
              <ng-template
                [ngIf]="orderType === OrderType.DineIn && (isOpenTab$ | async)"
              >
                <span class="rs-marketing-checkout__title mb-1">
                  {{ 'PAY_NOW.Authorise Payment' | translate | uppercase }}
                </span>

                <span class="mb-1">
                  {{
                    'PAY_NOW.Your payment will be authorized only' | translate
                  }}
                </span>
              </ng-template>

              <rs-contact-info></rs-contact-info>
              <span class="rs-marketing-checkout__title mb-1">
                {{ 'PAY_NOW.CHECKOUT' | translate | uppercase }}
              </span>

              <rs-order-information
                *ngIf="showDelivery"
                [orderMethod]="orderType | rsDeliveryTypeToDeliveryMethod"
                [isCheckout]="true"
              ></rs-order-information>

              <div class="d-flex justify-content-between">
                <div class="pt-3 pb-2">
                  <h3 class="rs-marketing-checkout__title m-0">
                    {{ 'PAY_NOW.Order Summary' | translate }}
                  </h3>
                  <div
                    class="rs-pay-now__items-info rs-marketing-checkout__hint mb-1"
                  >
                    <span>
                      {{
                        badgeNumber$
                          | async
                          | rsCartItemsAmount
                          | rsEasternArabicNumerals
                      }}
                    </span>
                  </div>
                </div>
                <div class="rs-pay-now__order-more fw-bold">
                  <a
                    rsTriggerClickOnEnter
                    tabindex="0"
                    [id]="id.checkout.orderMore"
                    (click)="goToOrdering()"
                  >
                    <mat-icon>add</mat-icon>
                    <span>
                      {{ 'PAY_NOW.ORDER_MORE' | translate | uppercase }}
                    </span>
                  </a>
                </div>
              </div>

              <rs-row-item-list class="rs-row-item-list" [items]="items">
                <ng-template let-item #item>
                  <ng-template
                    [ngIf]="
                      !isGiftCard && !(isThereAnyAvailableOrderMethods$ | async)
                    "
                  >
                    <rs-banner-alert
                      rs-status="error"
                      rs-rounded="true"
                      class="d-block w-fit-content mb-3"
                    >
                      {{ 'MENUS.unavailable_all_order_types' | translate }}
                    </rs-banner-alert>
                  </ng-template>

                  <div class="rs-pay-now__item">
                    <rs-gift-card-item-view
                      *ngIf="isGiftCard; else rsCheckoutItemDetails"
                      [card]="item"
                      class="flex-1"
                    ></rs-gift-card-item-view>

                    <ng-template #rsCheckoutItemDetails>
                      <rs-checkout-order-item
                        [item]="item"
                        [unavailableMessage]="
                          item.item.availability.isAvailable
                            ? orderType &&
                              (item.menuId
                                | rsIsAvailableMenuForCurrentOrderType
                                  : orderType
                                  : menus)
                              ? (item.menuId
                                | rsIsAvailableMenuForSelectedTime
                                  : orderType
                                  : selectedOrderPeriod
                                  : menus
                                  : item)
                                ? ''
                                : ('MENUS.this_item_is_unavailable_at_the_chosen_time'
                                  | translate)
                              : ('MENUS.unavailable_for_orderType'
                                | translate
                                  : { orderType: orderType | titlecase })
                            : ('MENUS.unavailable' | translate)
                        "
                        [bundleItemsNames]="bundleItemsNames$ | async"
                      ></rs-checkout-order-item>
                    </ng-template>
                  </div>
                </ng-template>
              </rs-row-item-list>
            </div>
          </div>

          <div
            class="rs-pay-now__payment-method rs-marketing-checkout__column--grey col-5 p-0 overflow-scroll"
          >
            <rs-marketing-checkout-check
              [isGiftCard]="isGiftCard"
              [isSearchingDriver]="isSearchingDriver"
            ></rs-marketing-checkout-check>

            <section class="mb-0 mt-3">
              <div class="rs-pay-now__items py-0">
                <h3 class="rs-marketing-checkout__title mb-0">
                  {{ 'PAY_NOW.Payment Details' | translate }}
                </h3>
              </div>
              <rs-pay-with
                (paymentMethodChanged)="reactOnPaymentMethodChanged($event)"
              ></rs-pay-with>
            </section>
            <ng-container
              *ngIf="checkCalculations$ | async as checkCalculations"
            >
              <ng-container *ngIf="venue$ | async as venue">
                <div
                  class="rs-pay-now__min-amount-error"
                  *ngIf="
                    !isGiftCard &&
                    orderType === deliveryMethod &&
                    checkCalculations.grossTotal <
                      (venue?.orderMinimum?.amount || 0)
                  "
                >
                  <rs-error-info [showClose]="false">
                    <div class="rs-pay-now__min-amount-error-content">
                      {{
                        'PAY_NOW.Delivery Orders have a minimum charge of'
                          | translate
                      }}
                      <b>{{
                        venue?.orderMinimum?.amount
                          | cents2Dollars
                          | currency: 'USD':'symbol':'1.2-2'
                      }}</b>
                    </div>
                  </rs-error-info>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                (paymentMethod$ | async) === paymentMethods.card &&
                isCardExpired === true
              "
            >
              <div class="rs-pay-now__min-amount-error">
                <rs-error-info [showClose]="false">
                  <div class="rs-pay-now__min-amount-error-content">
                    {{
                      'PAY_NOW.The selected card has expired, please use a different one'
                        | translate
                    }}
                  </div>
                </rs-error-info>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                !isGiftCard &&
                orderType === deliveryMethod &&
                !isLoggedIn &&
                !phoneIsEntered
              "
            >
              <div class="rs-pay-now__min-amount-error">
                <rs-error-info [showClose]="false">
                  <div class="rs-pay-now__min-amount-error-content">
                    {{
                      'PAY_NOW.Phone number is required for delivery orders'
                        | translate
                    }}
                  </div>
                </rs-error-info>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                (currentPaymentMethod === 'card' ||
                  currentPaymentMethod === 'gift_card') &&
                !isLoggedIn &&
                !(isShadowUser$ | async) &&
                !(guestUserData$ | async)?.isFormDataValid
              "
            >
              <div class="rs-pay-now__min-amount-error">
                <rs-error-info [showClose]="false">
                  <div class="rs-pay-now__min-amount-error-content">
                    {{ 'PAY_NOW.enter_contact_info' | translate }}
                  </div>
                </rs-error-info>
              </div>
            </ng-container>
            <div
              class="d-flex justify-content-center mb-5 mx-4"
              [ngClass]="{
                'd-none':
                  (paymentMethod$ | async) == paymentMethods.card
                    ? isLoggedIn
                      ? !(selectedPaymentCard$ | async)?.id
                      : !(guestUserData$ | async)?.isCardValid
                    : false
              }"
            >
              <ng-container
                *ngFor="
                  let payDisabled of [
                    (!isGiftCard &&
                      orderType === deliveryMethod &&
                      !(isAddressCoveredByDelivery$ | async)) ||
                      !(isOrderReadyForPayment$ | async) ||
                      (!isGiftCard &&
                        ((orderType === deliveryMethod &&
                          ((checkCalculations$ | async)!.grossTotal <
                            (venue$ | async)?.orderMinimum?.amount ||
                            !(isLoggedIn || phoneIsEntered))) ||
                          !(
                            isLoggedIn ||
                            (isShadowUser$ | async) ||
                            (guestUserData$ | async)?.isFormDataValid
                          ))) ||
                      (!isGiftCard &&
                        (checkCalculations$ | async)!.grossTotal <= 0)
                  ]
                "
              >
                <button
                  *ngIf="
                    currentPaymentMethod === 'card' ||
                    currentPaymentMethod === 'gift_card'
                  "
                  rs-style="bold"
                  [id]="id.checkout.payButton"
                  class="w-100"
                  [disabled]="
                    payDisabled ||
                    ((paymentMethod$ | async) === paymentMethods.card &&
                      isCardExpired === true)
                  "
                  (click)="payClicked.emit()"
                >
                  <span>{{ 'PAY_NOW.PAY' | translate | uppercase }}</span>
                </button>

                <ng-container
                  *ngIf="{
                    total: checkCalculations$ | async,
                    tips: tips$ | async
                  } as result"
                >
                  <rs-apple-pay
                    *ngIf="currentPaymentMethod === 'apple-pay' && !payDisabled"
                    [venueName]="venueName"
                    (payClicked)="payClicked.emit()"
                    [totalPrice]="
                      (isGiftCard
                        ? (price$ | async | cents2Dollars)
                        : ((result.total?.netTotal || 0) +
                            (result.tips?.absolute || 0) | cents2Dollars)
                      )?.toString() || '0.00'
                    "
                  ></rs-apple-pay>

                  <button
                    *ngIf="currentPaymentMethod === 'apple-pay' && payDisabled"
                    rs-style="bold"
                    disabled
                    class="rs-apple-pay__placeholder-button"
                  >
                    <mat-icon [svgIcon]="appleIcon"></mat-icon>
                  </button>

                  <button
                    *ngIf="currentPaymentMethod === 'google-pay' && payDisabled"
                    rs-style="bold"
                    disabled
                    class="rs-google-pay__placeholder-button"
                  >
                    <mat-icon [svgIcon]="googleIcon"></mat-icon>
                  </button>

                  <rs-google-pay
                    *ngIf="
                      currentPaymentMethod === 'google-pay' && !payDisabled
                    "
                    [venueName]="venueName"
                    (payClicked)="payClicked.emit()"
                    [totalPrice]="
                      (isGiftCard
                        ? (price$ | async | cents2Dollars)
                        : ((result.total?.netTotal || 0) +
                            (result.tips?.absolute || 0) | cents2Dollars)
                      )?.toString() || '0.00'
                    "
                  ></rs-google-pay>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
