<ng-container *ngIf="orderType$ | async as orderType">
  <ng-container *ngIf="selectedOrderPeriod$ | async as selectedOrderPeriod">
    <rs-full-height-dialog
      rs-dialog-header-with-border
      [dir]="'languageDirection' | translate"
    >
      <ng-container rs-dialog-header>
        <mat-icon
          rsTriggerClickOnEnter
          mat-dialog-close
          tabindex="0"
          class="cursor-pointer rs-icon-back"
          [id]="id.Cart.backButton"
          [svgIcon]="backIcon"
        ></mat-icon>

        {{ 'CART.ORDER' | translate }}
      </ng-container>

      <section
        *ngIf="badgeNumber$ | async as badgeNumber"
        class="d-flex rs-cart-dialog-main-view__body"
        [dir]="'languageDirection' | translate"
      >
        <div class="rs-cart-dialog-main-view__body--left">
          <rs-paying-with
            *ngIf="isLoggedIn && !hidePayingWith"
            (paymentHandled)="isPaymentSelected = $event"
            [hideTips]="isGiftCardInCart"
          ></rs-paying-with>

          <ng-content></ng-content>
        </div>

        <div class="rs-cart-dialog-main-view__body--right">
          <div
            *ngIf="!isLoggedIn && !isShadowUser"
            class="mt-2 mb-2 rs-cart-dialog-main-view__contact"
          >
            <rs-contact-info-notification></rs-contact-info-notification>
          </div>

          <div class="rs-cart-dialog-main-view__summary">
            <h2 class="rs-cart-dialog-main-view__summary-title">
              {{ 'CART.Summary' | translate | uppercase }}
            </h2>

            <div class="d-flex justify-content-between">
              <p class="rs-cart-dialog-main-view__summary-details">
                {{ 'CART.SUBTOTAL' | translate }}
              </p>
              <p
                [id]="id.Cart.subtotalAmount"
                class="rs-cart-dialog-main-view__summary-details"
              >
                {{
                  (price$ | async)!
                    | cents2DollarsView
                    | rsEasternArabicNumerals
                }}
              </p>
            </div>

            <div class="d-flex justify-content-between">
              <ng-container
                [ngTemplateOutlet]="rsCartActions"
                [ngTemplateOutletContext]="{
                  isOpenTabAvailable: orderType === dineInOrder && allowOpenTab
                }"
              ></ng-container>
            </div>
          </div>
        </div>
      </section>

      <ng-container rs-dialog-actions-direction="column">
        <ng-container
          [ngTemplateOutlet]="rsCartActions"
          [ngTemplateOutletContext]="{
            isOpenTabAvailable: orderType === dineInOrder && allowOpenTab
          }"
        ></ng-container>
      </ng-container>
    </rs-full-height-dialog>
  </ng-container>
</ng-container>

<ng-template #rsCartActions let-isOpenTabAvailable="isOpenTabAvailable">
  <button
    [id]="id.Cart.cartCheckoutButton"
    rs-style="bold"
    [disabled]="checkoutButtonDisabled"
    (click)="goToCheckout()"
    class="rs-cart-dialog-main-view__button"
    [ngClass]="{
      'rs-cart-dialog-main-view__button--only-one': !isOpenTabAvailable
    }"
  >
    <span>
      {{
        (isOpenTabAvailable ? 'CART.pay_now' : 'CART.Checkout')
          | translate
          | uppercase
      }}
    </span>
  </button>
  <button
    *ngIf="isOpenTabAvailable"
    rs-style="bold"
    class="rs-cart-dialog-main-view__button"
    [disabled]="checkoutButtonDisabled"
    (click)="openTab()"
  >
    <span>
      {{
        (isOpenTab ? 'CART.ADD_TO_TAB' : 'CART.OPEN_TAB')
          | translate
          | uppercase
      }}
    </span>
  </button>
</ng-template>
