<a
  #rsFoodItem
  rsTriggerClickOnEnter
  class="rs-food-item d-flex cursor-pointer"
  data-type="foodItem"
  tabindex="0"
  [dir]="'languageDirection' | translate"
  [ngClass]="{
    'rs-food-item--no-image': !itemImage,
    'rs-food-item--with-image': itemImage
  }"
  [id]="_item.itemId + '-section_' + sectionId"
  *ngIf="_item && getItemLink().url.length"
  [routerLink]="getItemLink().url"
  [queryParams]="getItemLink().queryParams"
  (click)="disableRedirection && $event.preventDefault()"
>
  <ng-container *ngFor="let itemNumberInCart of [itemNumberInCart$ | async]">
    <div class="rs-food-item__description">
      <div
        [ngClass]="{
          'rs-food-item__item-name-wrapper-with-favorite-button':
            showFavoriteButton && !itemImage
        }"
        [style.marginLeft]="
          ('languageDirection' | translate) === 'rtl'
            ? titleMargin + 'px'
            : 'unset'
        "
        [style.marginRight]="
          ('languageDirection' | translate) === 'rtl'
            ? 'unset'
            : titleMargin + 'px'
        "
        class="rs-food-item__item-name-wrapper d-flex flex-wrap"
      >
        <div
          [ngClass]="{
            'rs-food-item__item-name--no-image': !itemImage
          }"
          class="rs-food-item__item-name rs-two-lines-text-ellipsis flex-1"
        >
          {{ itemName }}
        </div>

        <div
          *ngIf="showCalories"
          class="rs-food-item__item-calories align-self-end"
        >
          <!-- TODO change to specific value when calories are available -->
          (300 cal)
          <!-- TODO end -->
        </div>
      </div>
      <div
        *ngIf="
          _item.alcoholicAttributes?.string === 'wine' || _item.description
        "
        class="rs-food-item__item-description rs-two-lines-text-ellipsis"
      >
        <ng-container
          *ngIf="
            _item.alcoholicAttributes?.string === 'wine';
            else rsNoAlcoholicDescription
          "
        >
          <ng-container
            *ngTemplateOutlet="rsAlcoholicDescription"
          ></ng-container>
        </ng-container>
      </div>
      <div
        [ngClass]="{
          'rs-food-item__footer--no-image': !itemImage
        }"
        class="rs-food-item__footer w-100 d-flex justify-content-between"
      >
        <div class="rs-food-item__price">
          {{ (
            _item?.prices?.[0]?.price?.amount
            || _item?.price?.amount
            || _item?.price?.value
            || 0
            ) | cents2DollarsView
              | rsEasternArabicNumerals }}
        </div>
        <div
          (click)="$event.stopPropagation()"
          *ngIf="_item.allergens?.length"
          [ngClass]="{
            'rs-food-item__allergens--right-spacing':
              showRating && showItemNumberInCart && !itemImage,
            'rs-food-item__allergens--right-spacing-only-for-rating':
              showRating && !showItemNumberInCart && !itemImage,
            'rs-food-item__allergens--right-spacing-only-for-items-number':
              !showRating && showItemNumberInCart && !itemImage,
            'rs-food-item__allergens--mobile': !_item.allergens?.length,
            'rs-food-item__allergens--desktop': !!_item.allergens?.length
          }"
        >
          <rs-item-allergens
            [itemAllergens]="_item.allergens"
            [itemName]="itemName"
            [showOnlyOneLine]="true"
            [small20pxIcons]="true"
          ></rs-item-allergens>
        </div>
      </div>
    </div>
    <div
      #rsImage
      [style.backgroundImage]="itemImage ? 'url(' + itemImage + ')' : ''"
      aria-hidden="true"
      class="rs-food-item__image-wrapper"
    >
      <img [src]="itemImage" [alt]="_item.customerFacingName || _item.name" class="rs-food-item__image" />
    </div>
    <div
      [ngClass]="{
        'justify-content-between': showFavoriteButton,
        'justify-content-end': !showFavoriteButton,
        'rs-food-item__actions-on-image--small': itemImage
      }"
      class="rs-food-item__actions-on-image d-flex flex-column align-items-end"
    >
      <a
        *ngIf="showFavoriteButton"
        rsTriggerClickOnEnter
        id="toggleItemAsFavorite"
        tabindex="0"
        class="rs-food-item__icon-wrapper d-flex align-items-center justify-content-center"
        [ngClass]="{
          'rs-food-item__icon-wrapper--no-image': !itemImage,
          'rs-food-item__icon-wrapper--on-image': !!itemImage,
          'rs-food-item__icon-wrapper--active': isFavoriteItem
        }"
        (click)="setFavorite($event)"
      >
        <label for="toggleItemAsFavorite" class="visually-hidden">
          {{
            (isFavoriteItem
              ? 'ORDER.remvove {item} from favorites'
              : 'ORDER.add {item} to favorites'
            ) | translate: { item: itemName }
          }}
        </label>
        <mat-icon
          [ngClass]="{ 'rs-food-item__icon--active': isFavoriteItem }"
          [svgIcon]="isFavoriteItem ? heartIcon : heartOutlineIcon"
          class="rs-food-item__icon rs-food-item__icon--active"
        ></mat-icon>
      </a>
      <span>
        <div
          *ngIf="showRating"
          [ngClass]="{
            'rs-food-item__rating--grey-background': !itemImage,
            'rs-food-item__rating--white-background': !!itemImage
          }"
          class="rs-food-item__rating rs-food-item__rating--with-item-number d-flex align-items-center justify-content-center"
        >
          <mat-icon [svgIcon]="starIcon" class="rs-food-item__icon"></mat-icon>
          <span class="rs-food-item__rating-value">
            <!-- TODO change to specific value when rating is available -->
            5.0
            <!-- TODO end -->
          </span>
        </div>
        <a
          *ngIf="showItemNumberInCart"
          tabindex="0"
          id="addItemToCart"
          class="rs-food-item__items-number d-flex align-items-center justify-content-center"
          [ngClass]="{
            'rs-food-item__icon-wrapper--no-image': !itemImage,
            'rs-food-item__icon-wrapper--on-image': !!itemImage
          }"
        >
          <label for="addItemToCart" class="visually-hidden">
            {{ 'ORDER.add {item} to cart' | translate: { item: itemName } }}
          </label>
          <span class="rs-food-item__items-number-value">
            <ng-container *ngIf="itemNumberInCart; else rsPlusButton">
              {{ itemNumberInCart }}
            </ng-container>

            <ng-template #rsPlusButton>
              <mat-icon
                class="rs-food-item__items-number-value-icon"
                [svgIcon]="addIcon"
              ></mat-icon>
            </ng-template>
          </span>
        </a>
      </span>
    </div>
    <div
      *ngIf="unavailable"
      aria-hidden="true"
      class="rs-food-item__unavailable-overlay position-absolute w-100"
    >
      <div
        [ngClass]="{
          'rs-food-item__unavailable-overlay-wrapper--on-image': !!itemImage,
          'rs-food-item__unavailable-overlay-wrapper--no-image': !itemImage
        }"
        class="w-100 position-relative d-flex rs-food-item__unavailable-overlay-wrapper"
      >
        <div
          #rsUnavailableMessage
          [id]="'unavailable-message-' + _item.itemId"
          [style.left]="
            ('languageDirection' | translate) === 'rtl'
              ? unavailableMessageRight + 'px'
              : 'unset'
          "
          [style.right]="
            ('languageDirection' | translate) === 'rtl'
              ? 'unset'
              : unavailableMessageRight + 'px'
          "
          class="rs-food-item__unavailable-overlay-message"
        >
          <span>{{ 'MENUS.unavailable' | translate }}</span>

          <mat-icon
            *ngIf="
              unavailableForSelectedTime || !_item.availability.isAvailable
            "
            [svgIcon]="clockIcon"
          ></mat-icon>

          <rs-order-method-icon
            *ngIf="
              !unavailableForSelectedTime &&
              _item.availability.isAvailable &&
              unavailableForOrderType
            "
            [orderType]="orderType"
          ></rs-order-method-icon>
        </div>
      </div>
    </div>
  </ng-container>
</a>

<ng-template #rsNoAlcoholicDescription>
  {{ _item.description }}
</ng-template>

<ng-template #rsAlcoholicDescription>
  <div class="rs-food-item__title">
    <div
      *ngIf="showFullAlcoholDetails"
      class="rs-food-item__title-line rs-food-item__title-line--gap"
    >
      <ng-container
        *ngIf="
          _item?.alcoholicAttributes?.wineAttributes?.alcoholicBeverage
            ?.ratings as wineRatings
        "
      >
        <rs-badge-status rs-color="#E73535" rs-style="stadium">
          {{ wineRatings | rsWineReviewsSummary }}/100
        </rs-badge-status>

        <div class="rs-food-item__title-critic-review">
          {{
            wineRatings.length === 1
              ? ('WINE.1 critic review' | translate)
              : ('WINE.{count} critic reviews'
                | translate: { count: wineRatings.length })
          }}
        </div>
      </ng-container>
    </div>

    <div
      class="rs-food-item__title-line"
      [ngClass]="{
        'rs-food-item__title-line--gap': showFullAlcoholDetails,
        'rs-food-item__title-line--column': !showFullAlcoholDetails
      }"
    >
      <div class="m-0 rs-food-item__item-description-text">
        <span *ngIf="!showFullAlcoholDetails">
          {{ _item.alcoholicAttributes?.wineAttributes?.origin?.chateauDomain }}
        </span>
        <rs-country-flag
          *ngIf="_item?.origin"
          [code3]="_item?.origin?.country || ''"
        ></rs-country-flag>
      </div>
      <div class="m-0 rs-food-item__item-description-text">
        {{ _item.alcoholicAttributes?.wineAttributes?.origin?.winery }},
        {{ _item.origin?.country || '' | getCountryNameByCode3 }}
      </div>
    </div>
  </div>

  <div
    *ngIf="showFullAlcoholDetails && _item.description"
    class="mt-2 rs-food-item__item-description-text"
  >
    {{ _item.description }}
  </div>
</ng-template>
