import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-drawer-title',
  standalone: true,
  imports: [CommonModule],
  template: '<h1 class="rs-drawer-title"><ng-content></ng-content></h1>',
})
export class RsDrawerTitleComponent {}

@Component({
  selector: 'rs-drawer-section-title',
  standalone: true,
  imports: [CommonModule],
  template:
    '<h2 class="rs-drawer-section-title"><ng-content></ng-content></h2>',
})
export class RsDrawerSectionHeaderComponent {}

@Component({
  selector: 'rs-drawer-item',
  standalone: true,
  imports: [CommonModule],
  template: `<li
    class="rs-drawer-item"
    tabindex="0"
    [ngClass]="{
      'rs-drawer-item--active': active,
      'rs-drawer-item--disabled': disabled
    }"
  >
    <ng-content></ng-content>
  </li>`,
})
export class RsDrawerItemComponent {
  @Input() disabled!: boolean;
  @Input() active!: boolean;
}

@Component({
  selector: 'rs-drawer-list',
  standalone: true,
  imports: [CommonModule],
  template: `<ol
    class="rs-drawer-list"
    [ngClass]="{ 'rs-drawer-list--with-separator': isSeparator }"
  >
    <ng-content></ng-content>
  </ol>`,
})
export class RsDrawerListComponent {
  @Input('rs-separator') set separator(_: any) {
    this.isSeparator = true;
  }

  public isSeparator: boolean = false;
}

@Component({
  selector: 'rs-drawer',
  standalone: true,
  imports: [CommonModule],
  template: '<div class="rs-drawer"><ng-content></ng-content></div>',
})
export class RsDrawerComponent {}
